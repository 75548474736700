<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mb-2"
          @click="showModal('add')"
        >
          Add Address
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-card-code
          title="Address"
          no-body
        >
          <b-card-body>
            <div class="d-flex justify-content-between flex-wrap">
              <!-- sorting  -->
              <b-form-group
                label="Sort"
                label-size="sm"
                label-align-sm="left"
                label-cols-sm="3"
                label-for="sortBySelect"
                class="mr-1 mb-md-0"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                  >
                    <template #first>
                      <option value="">
                        none
                      </option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    :disabled="!sortBy"
                  >
                    <option :value="false">
                      ASC
                    </option>
                    <option :value="true">
                      DESC
                    </option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>

              <!-- filter -->
              <b-form-group
                label="Filter"
                label-cols-sm="2"
                label-align-sm="left"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </b-card-body>

          <b-table
            striped
            hover
            responsive
            show-empty
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(country)="data">
              <p class="m-0">
                {{ data.item.country }}
              </p>
              <small class="text-muted">
                {{ `${data.item.state}, ${data.item.city}` }}
              </small>
            </template>
            <template #cell(actions)="data">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                  boundary="window"
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="SettingsIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="showModal('edit', data.item.id)">
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                      style="margin-top: -5px"
                    />
                    <span>Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="deleteAddress(data.item.id)">
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                      style="margin-top: -5px"
                    />
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </template>
          </b-table>

          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- page length -->
            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                inline
                :options="pageOptions"
              />
            </b-form-group>

            <!-- pagination -->
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-card-code>
      </b-col>
    </b-row>

    <b-modal
      id="modal-add-edit"
      v-model="modal.show"
      no-close-on-backdrop
      size="lg"
      cancel-variant="outline-secondary"
      ok-title="Submit"
      cancel-title="Close"
      :title="modal.title"
    >
      <b-form ref="form-socmed">
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Address"
              label-for="address_text"
            >
              <b-form-textarea
                id="address_text"
                v-model="input.address"
                placeholder="Address"
                rows="3"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Country"
              label-for="country"
            >
              <b-form-select
                id="country"
                v-model="input.country"
                :options="countries"
                @change="getStates()"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="State"
              label-for="state"
            >
              <b-form-select
                id="state"
                v-model="input.state"
                :options="states"
                @change="getCities()"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="City"
              label-for="city"
            >
              <b-form-select
                id="city"
                v-model="input.city"
                :options="cities"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Postal Code"
              label-for="postal_code"
            >
              <b-form-input
                id="postal_code"
                v-model="input.postal_code"
                type="number"
                placeholder="Postal Code"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Order"
              label-for="order"
            >
              <b-form-input
                id="order"
                v-model="input.order"
                type="number"
                placeholder="Order"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Default Address"
              label-for="default_address"
            >
              <b-form-checkbox
                v-model="input.default"
                class="custom-control-primary"
                name="check-button"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            class="float-right"
            :disabled="button.disabled"
            @click="handleAddress"
          >
            {{ button.loading_text }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow, BCol,
  BTable, BCardBody,
  BForm, BFormTextarea, BFormGroup, BFormSelect, BFormInput, BFormCheckbox,
  BInputGroup, BInputGroupAppend, BButton,
  BDropdown, BDropdownItem, BPagination,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BTable,
    BCardBody,
    BForm,
    BFormTextarea,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BPagination,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      perPage: 50,
      pageOptions: [10, 50, 100],
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'address', label: 'Address', sortable: true },
        { key: 'country', label: 'Country, State, City' },
        { key: 'postal_code', label: 'Postal Code' },
        { key: 'order', label: 'Order', sortable: true },
        { key: 'actions', label: 'Actions' },
      ],
      active_role: null,

      modal: {
        show: false,
        title: null,
        action: null,
      },
      button: {
        disabled: false,
        loading_text: 'Submit',
      },
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    items() {
      return this.$store.state.corporate.corporate_address
    },
    totalRows() {
      return this.$store.state.corporate.corporate_address.length
    },
    countries() {
      return this.$store.state.csc.countries
    },
    states() {
      return this.$store.state.csc.states
    },
    cities() {
      return this.$store.state.csc.cities
    },
    input() {
      return this.$store.state.corporate.corporate_address_detail
    },
  },
  watch: {
    'input.country': value => {
      if (value) {
        this.$store.dispatch('csc/loadStatesByCountry', value)
      }
    },
    'input.state': value => {
      if (value) {
        this.$store.dispatch('csc/loadCitiesByStates', value)
      }
    },
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    // Modal action
    handleAddress() {
      this.button.disabled = true
      this.button.loading_text = 'Loading...'

      const { id } = this.$route.params
      const type = this.modal.action

      if (type === 'add') {
        this.input.corporate_id = id
        this.$store.dispatch('corporate/addAddressCorporate', this.input)
          .then(res => {
            this.createToast('success', 'Data submit', res.data.message)
            this.closeModal()

            // refresh data
            this.refreshData()
          })
          .catch(() => {
            this.createToast('danger', 'Data submit', 'Terjadi kesalahan input')
          })
      } else if (type === 'edit') {
        this.$store.dispatch('corporate/editAddressCorporate', this.input)
          .then(res => {
            this.createToast('success', 'Data submit', res.data.message)
            this.closeModal()

            // refresh data
            this.refreshData()
          })
          .catch(() => {
            this.createToast('danger', 'Data submit', 'Terjadi kesalahan input')
          })
      }

      this.resetButton()
    },
    showModal(type, id = null) {
      this.modal.show = true
      this.modal.action = type

      if (type === 'add') {
        this.$store.dispatch('corporate/resetCorporateAddress')
        this.$store.dispatch('csc/resetState')
        this.$store.dispatch('csc/resetCity')

        this.modal.title = 'Add Address'
      } else if (type === 'edit') {
        this.$store.dispatch('corporate/loadDetailAddress', id)
        this.modal.title = 'Edit Address'
      }
    },
    resetButton() {
      this.button.disabled = false
      this.button.loading_text = 'Submit'
    },
    closeModal() {
      this.modal.show = false
      this.modal.action = null
      this.modal.title = null
    },

    refreshData() {
      // reset data
      const { id } = this.$route.params
      this.$store.dispatch('corporate/loadAddressCorporate', id)
    },
    getStates() {
      const { country } = this.input
      this.$store.dispatch('csc/loadStatesByCountry', country)
    },
    getCities() {
      const { state } = this.input
      this.$store.dispatch('csc/loadCitiesByStates', state)
    },
  },
}
</script>
