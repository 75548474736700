<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mb-2"
          @click="showModal('add')"
        >
          Add Partner
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-card-code
          title="Partners"
          no-body
        >
          <b-card-body>
            <div class="d-flex justify-content-between flex-wrap">
              <!-- sorting  -->
              <b-form-group
                label="Sort"
                label-size="sm"
                label-align-sm="left"
                label-cols-sm="3"
                label-for="sortBySelect"
                class="mr-1 mb-md-0"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                  >
                    <template #first>
                      <option value="">
                        none
                      </option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    :disabled="!sortBy"
                  >
                    <option :value="false">
                      ASC
                    </option>
                    <option :value="true">
                      DESC
                    </option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>

              <!-- filter -->
              <b-form-group
                label="Filter"
                label-cols-sm="2"
                label-align-sm="left"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </b-card-body>

          <b-table
            striped
            hover
            responsive
            show-empty
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(name)="data">
              <div class="d-flex flex-row">
                <b-avatar
                  v-if="data.item.logo !== null"
                  class="mr-50"
                  :src="`${data.item.logo}`"
                />
                <b-avatar
                  v-else
                  class="mr-50"
                  variant="primary"
                  :text="data.item.name | initial"
                />
                <div class="d-flex flex-column">
                  <p class="m-0">
                    {{ data.item.name }}
                  </p>
                  <small class="text-muted">
                    <feather-icon icon="ActivityIcon" /> {{ data.item.date }}
                  </small>
                </div>
              </div>
            </template>
            <template #cell(expired)="data">
              <b-badge
                v-if="!data.item.expired"
                variant="light-success"
              >
                Active
              </b-badge>
              <b-badge
                v-else
                variant="light-danger"
              >
                Expired
              </b-badge>
            </template>
            <template #cell(actions)="data">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                  boundary="window"
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="SettingsIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="showModal('edit', data.item.id)">
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                      style="margin-top: -5px"
                    />
                    <span>Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="deletePartner(data.item.id)">
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                      style="margin-top: -5px"
                    />
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </template>
          </b-table>

          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- page length -->
            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                inline
                :options="pageOptions"
              />
            </b-form-group>

            <!-- pagination -->
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-card-code>
      </b-col>
    </b-row>

    <b-modal
      id="modal-add-edit"
      v-model="modal.show"
      no-close-on-backdrop
      size="lg"
      cancel-variant="outline-secondary"
      ok-title="Submit"
      cancel-title="Close"
      :title="modal.title"
    >
      <b-form ref="form-member">
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Title"
              label-for="first_name"
              required
            >
              <b-form-input
                id="name"
                v-model="input.title"
                placeholder="Title"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Upload Logo"
              label-for="first_name"
            >
              <b-form-file
                ref="file-input"
                accept="image/*"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                @change="setFileImage"
              />
            </b-form-group>
            <div
              v-if="input.file.url"
              style="position: relative; width: 50%"
            >
              <b-img
                :src="input.file.url"
                rounded
                alt="Rounded image"
                class="d-inline-block mr-1 mb-1 w-100"
              />
              <div
                style="position: absolute; top: 0.45rem; right: 0.5rem"
                @click="resetImage"
              >
                <b-button
                  variant="gradient-danger"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </div>
            </div>
          </b-col>
          <b-col cols="12">
            <label for="description">Description</label>
            <b-form-textarea
              id="description"
              v-model="input.description"
              placeholder="Description"
              rows="3"
            />
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="mt-1"
          >
            <b-form-group
              label="Date"
              label-size="sm"
              label-for="filterDate"
            >
              <b-input-group>
                <flat-pickr
                  id="filterDate"
                  v-model="input.date"
                  class="form-control"
                  :config="{ mode: 'range'}"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="mt-2"
          >
            <b-form-group
              label="Pin Code"
              label-size="sm"
              required
            >
              <b-input-group>
                <b-form-input
                  v-model="input.pin_code"
                  type="number"
                  placeholder="Pin Code"
                />
                <b-input-group-append>
                  <b-button
                    variant="primary"
                    @click="generateCode()"
                  >
                    Generate
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Category"
              label-size="sm"
              label-for="category"
            >
              <b-form-select
                id="category"
                v-model="input.category"
                :options="options_categories"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="modal.action == 'add'"
            cols="12"
            md="6"
            class="mt-1"
          >
            <b-form-group
              label="Parent"
              label-size="sm"
              label-for="category"
            >
              <b-form-checkbox
                v-model="input.has_parent"
                switch
                class="custom-control-primary"
                name="check-button"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col
            v-if="input.has_parent && modal.action == 'add'"
            cols="12"
            md="6"
          >
            <b-form-group
              label="Parent"
              label-size="sm"
              label-for="parent"
            >
              <b-form-select
                id="parent"
                v-model="input.parent"
                :options="options_partner"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-else-if="!input.has_parent && modal.action == 'add'"
            cols="12"
            md="6"
          >
            <b-form-group
              label="Title Parent"
              label-size="sm"
              label-for="title-parent"
            >
              <b-form-input
                id="title-parent"
                v-model="input.title_parent"
                placeholder="Title"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            class="float-right"
            :disabled="button.disabled"
            @click="handlePartner"
          >
            {{ button.loading_text }}
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="crop-image"
      ref="cropimage"
      v-model="modal_crop_image"
      static
      title="Crop Gambar"
      @hidden="resetCropImage"
      @ok="handleCropImage"
    >
      <vue-cropper
        ref="cropper"
        :src="input.file_raw"
        :crop-box-resizable="false"
        :toggle-drag-mode-on-dblclick="false"
      />
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mt-1"
        @click="setCropArea"
      >
        Set Default Crop
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol,
  BAvatar, BBadge, BCardBody, BTable,
  BForm, BFormGroup, BFormInput, BFormSelect, BFormFile, BFormTextarea, BFormCheckbox,
  BInputGroup, BInputGroupAppend, BButton,
  BDropdown, BDropdownItem, BPagination, BImg,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import flatPickr from 'vue-flatpickr-component'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
  components: {
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BCardBody,
    BTable,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormFile,
    BFormTextarea,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    flatPickr,
    VueCropper,
    BCardCode,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      perPage: 50,
      pageOptions: [10, 50, 100],
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'total', label: 'Cabang', sortable: true },
        { key: 'expired', label: 'Status' },
        { key: 'actions', label: 'Actions' },
      ],
      active_role: null,

      modal: {
        show: false,
        title: null,
        action: null,
      },
      button: {
        disabled: false,
        loading_text: 'Submit',
      },
      modal_crop_image: false,

      options_categories: [
        { text: 'FnB', value: 'fnb' },
        { text: 'Non FnB', value: 'nonfnb' },
      ],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    items() {
      return this.$store.state.corporate.corporate_partner
    },
    totalRows() {
      return this.$store.state.corporate.corporate_partner.length
    },
    options_partner() {
      const partner = this.$store.state.corporate.corporate_partner
      const result = partner.map(item => ({
        text: item.name,
        value: item.id,
      }))

      return result
    },
    input() {
      return this.$store.state.partner.detail
    },
  },
  methods: {
    generateCode() {
      const result = Math.floor(Math.random() * (999999 - 100000)) + 100000
      this.input.pin_code = result
    },
    goToLink(link) {
      window.open(link, '_blank')
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    showModal(type, id = null) {
      this.modal.show = true
      this.modal.action = type

      if (type === 'add') {
        this.$store.dispatch('partner/resetPartner')
        this.modal.title = 'Add Partner'
      } else if (type === 'edit') {
        this.$store.dispatch('partner/loadDetailPartner', id)
        this.modal.title = 'Edit Partner'
      }
    },
    closeModal() {
      this.modal.show = false
      this.modal.action = null
      this.modal.title = null
    },
    handlePartner() {
      this.button.disabled = true
      this.button.loading_text = 'Loading...'

      const { id } = this.$route.params
      const type = this.modal.action
      const data = new FormData()

      // split start and end date
      const { date } = this.input
      const checkDateRange = date.includes(' to ')
      if (checkDateRange) {
        const dateSplit = date.split(' to ')
        const startDate = dateSplit[0]
        const endDate = dateSplit[1]

        data.append('id', this.input.id)
        data.append('corporate', id)
        data.append('title', this.input.title)
        data.append('logo', this.input.file.image)
        data.append('description', this.input.description)
        data.append('start_date', startDate)
        data.append('end_date', endDate)
        data.append('pin_code', this.input.pin_code)
        data.append('category', this.input.category)
        data.append('has_parent', (this.input.has_parent) ? 'yes' : 'no')
        data.append('id_partner', this.input.parent)
        data.append('title_parent', this.input.title_parent)

        if (type === 'add') {
          this.$store.dispatch('partner/addPartner', data)
            .then(res => {
              this.createToast('success', 'Data submit', res.data.message)
              this.closeModal()

              // refresh table
              this.refreshData()
            })
            .catch(() => {
              // this.createToast('danger', 'Data submit', "Terjadi kesalahan input")
            })
        } else if (type === 'edit') {
          const payload = {
            id: this.input.id,
            data,
          }
          this.$store.dispatch('partner/editPartner', payload)
            .then(res => {
              this.createToast('success', 'Data submit', res.data.message)
              this.closeModal()

              // refresh table
              this.refreshData()
            })
            .catch(() => {
              // this.createToast('danger', 'Data submit', "Terjadi kesalahan input")
            })
        }
      } else {
        this.createToast('danger', 'Data submit', 'The end date is empty')
      }

      this.resetButton()
    },
    resetButton() {
      this.button.disabled = false
      this.button.loading_text = 'Submit'
    },
    refreshData() {
      // reset data
      const { id } = this.$route.params
      this.$store.dispatch('corporate/loadPartnerCorporate', id)
    },
    deletePartner(id) {
      this.$bvModal
        .msgBoxConfirm('Are you sure to delete this data?', {
          title: 'Delete confirmation',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Delete',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$store.dispatch('partner/deletePartner', id)
              .then(res => {
                this.createToast('success', 'Delete data partner', res.data.message)
                this.closeModal()

                // refresh table
                this.refreshData()
              })
              .catch(() => {
                this.createToast('danger', 'Delete data partner', 'Terjadi kesalahan input')
              })
          }
        })
    },

    setFileImage(event) {
      const file = event.target.files[0]

      if (file.type.indexOf('image/') === -1) {
        this.$refs['file-input'].reset()
        this.runToast('error', 'Please select an image file')
        return
      }

      if (typeof FileReader === 'function') {
        const reader = new FileReader()

        reader.onload = eventReader => {
          const sizeFile = Math.floor(file.size / 1024)

          if (sizeFile <= 1024) {
            this.input.file_raw = eventReader.target.result

            this.showModalCrop()

            // rebuild cropperjs with the updated source
            this.$refs.cropper.replace(eventReader.target.result)
          } else {
            this.$refs['file-input'].reset()
            this.runToast('error', 'Ukuran file lebih dari 1 MB')
          }
        }
        reader.readAsDataURL(file)
      } else {
        this.$refs['file-input'].reset()
        this.runToast('error', 'Sorry, FileReader API not supported')
      }
    },
    showModalCrop() {
      this.modal_crop_image = true
    },
    resetImage() {
      this.$refs['file-input'].reset()

      this.input.file.image = null
      this.input.file.url = null
      this.input.file_raw = null
    },
    setCropArea() {
      this.$refs.cropper.setCropBoxData({
        width: 350,
        height: 200,
      })
    },
    resetCropImage() {
      if (!this.input.file_raw) {
        this.$refs['file-input'].reset()
      }
    },
    handleCropImage() {
      this.input.file.url = this.$refs.cropper.getCroppedCanvas().toDataURL()

      const block = this.input.file.url.split(';')
      const contentType = block[0].split(':')[1]
      const realData = block[1].split(',')[1]
      this.input.file.image = this.b64toBlob(realData, contentType)
    },
    b64toBlob(b64Data, contentType = '', sliceSize = 512) {
      const byteCharacters = atob(b64Data)
      const byteArrays = []

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)

        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i += 1) {
          byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)

        byteArrays.push(byteArray)
      }

      const blob = new Blob(byteArrays, { type: contentType })
      return blob
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
