<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mb-2"
          @click="showModalRole('add')"
        >
          Add Role
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-card-code
          title="Role"
          no-body
        >
          <b-card-body>
            <div class="d-flex justify-content-between flex-wrap">
              <!-- filter -->
              <b-form-group
                label="Filter"
                label-cols-sm="2"
                label-align-sm="left"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </b-card-body>

          <b-table
            striped
            hover
            responsive
            show-empty
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(access_dashboard)="data">
              <div v-if="(data.item.access_dashboard === 1)">
                <b-badge
                  class="align-middle"
                  variant="light-success"
                >
                  Yes
                </b-badge>
              </div>
              <div v-else>
                <b-badge
                  class="align-middle"
                  variant="light-danger"
                >
                  No
                </b-badge>
              </div>
            </template>
            <template #cell(actions)="data">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                  boundary="window"
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="SettingsIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="showModalRole('detail', data.item.id)">
                    <feather-icon
                      icon="ListIcon"
                      class="mr-50"
                      style="margin-top: -5px"
                    />
                    <span>Detail</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="showModalRole('edit', data.item.id)">
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                      style="margin-top: -5px"
                    />
                    <span>Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="deleteRole(data.item.id)">
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                      style="margin-top: -5px"
                    />
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </template>
          </b-table>

          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- page length -->
            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                inline
                :options="pageOptions"
              />
            </b-form-group>

            <!-- pagination -->
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-card-code>
      </b-col>
    </b-row>

    <b-modal
      id="modal-add-edit"
      v-model="modal_role.show"
      no-close-on-backdrop
      cancel-variant="outline-secondary"
      ok-title="Submit"
      cancel-title="Close"
      :title="modal_role.title"
    >
      <b-form ref="form-member">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Role Name"
              label-for="rolename"
            >
              <b-form-input
                id="rolename"
                v-model="input.name"
                placeholder="Role Name"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Access to Dashboard"
              label-size="sm"
              label-for="category"
            >
              <b-form-checkbox
                v-model="input.access_dashboard"
                switch
                class="custom-control-primary"
                name="check-button"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            class="float-right"
            :disabled="button_role.disabled"
            @click="handleRole"
          >
            {{ button_role.loading_text }}
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="modal-add-edit"
      v-model="modal_menu.show"
      no-close-on-backdrop
      cancel-variant="outline-secondary"
      ok-title="Submit"
      cancel-title="Close"
      :title="modal_menu.title"
    >
      <b-card-code
        :title="`Menu ${input.name}`"
        no-body
      >
        <b-card
          no-body
          class="border mt-1"
        >
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <span class="ml-50">
                Access Dashboard :
                <span v-if="(input.access_dashboard === true)">
                  <b-badge
                    class="align-middle"
                    variant="light-success"
                  >
                    Yes
                  </b-badge>
                </span>
                <span v-else>
                  <b-badge
                    class="align-middle"
                    variant="light-danger"
                  >
                    No
                  </b-badge>
                </span>
              </span>
            </b-card-title>
          </b-card-header>
          <b-table
            v-if="(input.access_dashboard === true)"
            striped
            responsive
            class="mb-0"
            :fields="fieldsData"
            :items="permissionsData"
          >
            <template #cell(module)="data">
              {{ data.value }}
            </template>
            <template #cell(read)="data">
              <b-form-checkbox
                v-model="data.item[data.field.key]"
                :checked="data.value"
              />
            </template>
          </b-table>
        </b-card>
      </b-card-code>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            class="float-right"
            :disabled="input.access_dashboard === true ? false : true"
            @click="handleMenu"
          >
            {{ button_menu.loading_text }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow, BCol,
  BTable, BBadge, BCardBody, BCard, BCardTitle, BCardHeader,
  BForm, BFormGroup, BFormSelect, BFormInput, BFormCheckbox,
  BInputGroup, BInputGroupAppend, BButton,
  BDropdown, BDropdownItem, BPagination,
  VBModal,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BTable,
    BBadge,
    BCardBody,
    BCard,
    BCardTitle,
    BCardHeader,
    BForm,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BPagination,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      perPage: 50,
      pageOptions: [10, 50, 100],
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'role_name', label: 'Name', sortable: true },
        { key: 'access_dashboard', label: 'Access Dashboard' },
        { key: 'actions', label: 'Actions' },
      ],
      active_role: null,

      modal_role: {
        show: false,
        title: null,
        action: null,
      },
      button_role: {
        disabled: false,
        loading_text: 'Submit',
      },
      modal_menu: {
        show: false,
        title: null,
        action: null,
      },
      button_menu: {
        disabled: false,
        loading_text: 'Submit',
      },
      fieldsData: [
        { key: 'module', label: 'Title' },
        { key: 'read', label: 'Permission' },
      ],
      permissionsData: [
        {
          id: 43,
          module: 'Dashboard',
          read: false,
        },
        {
          id: 45,
          module: 'User Member',
          read: false,
        },
        {
          id: 46,
          module: 'Top Transaction',
          read: false,
        },
        {
          id: 47,
          module: 'Top Seller',
          read: false,
        },
        {
          id: 48,
          module: 'Master Partner',
          read: false,
        },
        {
          id: 49,
          module: 'Partner Tracking',
          read: false,
        },
        {
          id: 50,
          module: 'Banner',
          read: false,
        },
        {
          id: 51,
          module: 'Event Data',
          read: false,
        },
        {
          id: 52,
          module: 'Log Event',
          read: false,
        },
        {
          id: 53,
          module: 'Log Member',
          read: false,
        },
      ],
    }
  },
  computed: {
    input() {
      return this.$store.state.role.detail
    },
    permissionRole() {
      return this.$store.state.role.detail.permission
    },
    items() {
      return this.$store.state.role.role
    },
    totalRows() {
      return this.$store.state.role.role.length
    },
  },
  watch: {
    permissionRole(newValue) {
      newValue.menu.forEach(element => {
        const p = this.permissionsData.find(item => item.module === element.name)
        p.read = true
      })
    },
  },
  methods: {
    showModalRole(type, id = null) {
      let modal = null
      if (type === 'add' || type === 'edit') {
        modal = this.modal_role
      } else if (type === 'detail') {
        modal = this.modal_menu
      }

      modal.show = true
      modal.action = type

      if (type === 'add') {
        modal.title = 'Add Role'
      } else if (type === 'edit') {
        this.$store.dispatch('role/loadDetailRole', id)
        modal.title = 'Edit Role'
      } else if (type === 'detail') {
        this.$store.dispatch('role/loadDetailRole', id)
        modal.title = 'Detail Role'
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    handleRole() {
      const { id } = this.$route.params
      this.input.corporate = id

      if (this.modal_role.action === 'add') {
        this.$store.dispatch('role/addRole', this.input)
          .then(res => {
            this.createToast('success', 'Data submit', res.data.message)
            this.closeModal('role')

            // refresh table
            this.refreshData()
          })
          .catch(() => {
            this.createToast('danger', 'Data submit', 'Terjadi kesalahan input')
          })
      } else {
        this.$store.dispatch('role/editRole', this.input)
          .then(res => {
            this.createToast('success', 'Data submit', res.data.message)
            this.closeModal('role')

            // refresh table
            this.refreshData()
          })
          .catch(() => {
            this.createToast('danger', 'Data submit', 'Terjadi kesalahan input')
          })
      }
    },
    handleMenu() {
      const { id } = this.permissionRole
      const menu = []
      const perm = this.permissionsData.filter(item => item.read === true)
      if (perm) {
        perm.forEach(element => {
          const dataPerm = {
            parent_id: element.id,
            role_id: id,
            read: 1,
          }
          menu.push(dataPerm)
        })
      }
      const data = {
        role_id: id,
        menu,
      }
      this.$store.dispatch('role/addRoleMenu', data)
        .then(res => {
          this.createToast('success', 'Data submit', res.data.message)
          this.closeModal('menu')

          // refresh table
          this.refreshData()
        })
        .catch(() => {
          this.createToast('danger', 'Data submit', 'Terjadi kesalahan input')
        })
    },
    deleteRole(id) {
      this.$bvModal
        .msgBoxConfirm('Are you sure to delete this data?', {
          title: 'Delete confirmation',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Delete',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$store.dispatch('role/deleteRole', id)
              .then(res => {
                this.createToast('success', 'Delete daata role', res.data.message)
                this.closeModal('role')

                // refresh table
                this.refreshData()
              })
              .catch(() => {
                this.createToast('danger', 'Delete dataa role', 'Terjadi kesalahan input')
              })
          }
        })
    },
    refreshData() {
      const { id } = this.$route.params
      this.$store.dispatch('role/loadRoleCorporate', id)
    },

    closeModal(type) {
      let modal = null
      if (type === 'role') {
        modal = this.modal_role
      } else if (type === 'menu') {
        modal = this.modal_menu
      }

      modal.show = false
      modal.action = null
      modal.title = null
    },
  },
}
</script>
