<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <div class="mb-2">
          <h5 class="text-capitalize mb-75">
            Corporate Name
          </h5>
          <p class="card-text">
            {{ corporate.name }}
          </p>
        </div>
        <div class="mb-2">
          <h5 class="text-capitalize mb-75">
            Phone
          </h5>
          <p class="card-text">
            {{ corporate.phone | phone(corporate.phone_code, corporate.phone) }}
          </p>
        </div>
        <div class="mb-2">
          <h5 class="text-capitalize mb-75">
            Commerce Group
          </h5>
          <p class="card-text">
            {{ corporate.commerce_group | handleNullValue }}
          </p>
        </div>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <div class="mb-2">
          <h5 class="text-capitalize mb-75">
            Contact Name
          </h5>
          <p class="card-text">
            {{ corporate.contact_name | handleNullValue }}
          </p>
        </div>
        <div class="mb-2">
          <h5 class="text-capitalize mb-75">
            Telephone
          </h5>
          <p class="card-text">
            {{ corporate.telephone | phone(corporate.telephone_code, corporate.telephone) }}
          </p>
        </div>
        <div class="mb-2">
          <h5 class="text-capitalize mb-75">
            Website
          </h5>
          <p class="card-text">
            {{ corporate.website | handleNullValue }}
          </p>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <div class="mb-2">
          <h5 class="text-capitalize mb-75">
            Description
          </h5>
          <p
            class="card-text"
            v-html="corporate.description"
          />
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow, BCol,
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
  },
  computed: {
    corporate() {
      return this.$store.state.corporate.corporate_detail
    },
  },
}
</script>
