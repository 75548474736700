<template>
  <div>
    <b-tabs
      vertical
      nav-wrapper-class="nav-vertical"
      class="mt-2"
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="SearchIcon" />
          Overview
        </template>
        <corporate-overview />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="UsersIcon" />
          Members
        </template>
        <corporate-member />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="PhoneIcon" />
          Social Media
        </template>
        <corporate-socmed />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="HomeIcon" />
          Address
        </template>
        <corporate-address />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ImageIcon" />
          Banners
        </template>
        <corporate-banner />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="UserCheckIcon" />
          Partners
        </template>
        <corporate-partner />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="CalendarIcon" />
          Events
        </template>
        <corporate-event />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ListIcon" />
          Roles &amp; Menus
        </template>
        <corporate-roles-menus />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="SettingsIcon" />
          Setting
        </template>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ActivityIcon" />
          Log
        </template>
        <corporate-log />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'

import CorporateOverview from './detail/CorporateOverview.vue'
import CorporateMember from './detail/CorporateMember.vue'
import CorporateSocmed from './detail/CorporateSocmed.vue'
import CorporateAddress from './detail/CorporateAddress.vue'
import CorporateBanner from './detail/CorporateBanner.vue'
import CorporatePartner from './detail/CorporatePartner.vue'
import CorporateEvent from './detail/CorporateEvent.vue'
import CorporateRolesMenus from './detail/CorporateRolesMenus.vue'
import CorporateLog from './detail/CorporateLog.vue'

export default {
  components: {
    BTabs,
    BTab,
    CorporateOverview,
    CorporateMember,
    CorporateSocmed,
    CorporateAddress,
    CorporateBanner,
    CorporatePartner,
    CorporateEvent,
    CorporateRolesMenus,
    CorporateLog,
  },
  created() {
    this.getDetailCorporate()
    this.getCountries()
  },
  methods: {
    getDetailCorporate() {
      const { id } = this.$route.params

      this.$store.dispatch('corporate/loadDetailCorporate', id)
      this.$store.dispatch('corporate/loadSocmedCorporate', id)
      this.$store.dispatch('corporate/loadAddressCorporate', id)
      this.$store.dispatch('corporate/loadBannerCorporate', id)
      this.$store.dispatch('corporate/loadPartnerCorporate', id)
      this.$store.dispatch('corporate/loadEventCorporate', id)
      this.$store.dispatch('role/loadRoleCorporate', id)
    },
    getCountries() {
      this.$store.dispatch('csc/loadCountries')
    },
  },
}
</script>
